<template>
  <div class="sprints">

    <template v-if="statusLoadSprint === 'loading'">
      <h1>Sprints</h1>
      <CircleLoader :ratio="1.5" class="sprints-load"/>
    </template>

    <template v-else-if="statusLoadSprint === 'not found'">
      <h1>Sprints</h1>
      <div class="empty-all">
        <IconNew name="sprint-first-icon" class="icon"/>
        <h1 class="light">Let’s start your first sprint</h1>
        <ButtonNew
            mode="text"
            icon-name="video"
            text="How to use sprints in academic research?"
            size="s"
            icon-position="left"
            @click="showFirstVideo = true"
        />
        <p class="secondary-text">
          A Sprint is a short, time-boxed period when we try to complete a set amount of work.<br>
          Sprints help you break down big, complex tasks into bite-sized pieces and get things done easier.
        </p>
        <ButtonNew mode="green" text="Create Sprint" size="l" class="button" @click="createNewSprint"/>
      </div>

      <Modal
          id="goals-video-first-sprint"
          v-model:show="showFirstVideo"
          is-center
          class="video-modal"
      >
        <video
            src="https://res.cloudinary.com/dewnqhcdc/video/upload/v1607902031/video/Sprints_r00dl5.mp4"
            width="1920"
            height="1080"
            frameborder="0"
            uk-video="autoplay: inview"
            uk-responsive
        ></video>
      </Modal>

    </template>

    <template v-else-if="statusLoadSprint === 'loaded'">

      <div class="sprint-choice">
        <h1>Sprint {{ activeSprint + 1 }}</h1>
        <IconNew name="sprint-dropdown" class="dropdown-icon" :class="[dropdownOpen? 'open': '']" @click="dropdownOpen = !dropdownOpen"/>
        <div
            class="sprint-choice-dropdown"
            uk-dropdown="boundary: .sprint-choice; boundary-align: true; offset: 5; animation: uk-animation-slide-top-small; duration: 100; mode:click; delay-hide: 0;"
        >
          <div class="sprint-choice-list">
            <template v-for="(sprint, index) in sprints" :key="'sprint-choice-item-' + index">
              <p class="sprint-choice-item"
                 :class="[
                     sprint.realEndDate && sprint.endDate &&
                     Date.parse(sprint.realEndDate) !== Date.parse(NOT_DATE) &&
                     Date.parse(sprint.realEndDate) <= Date.parse(sprint.endDate)? 'success': '',
                     index === sprints.length - 1 && Date.parse(sprint.realEndDate) === Date.parse(NOT_DATE)? 'now': '',
                     index === activeSprint? 'active': ''
                 ]"
                 @click="chooseSprint(index)"
              >Sprint {{index + 1}}</p>
            </template>
          </div>
        </div>
      </div>
      <SprintDetails
          :sprint="sprints[activeSprint]"
          @complete-sprint="completeLastSprint"
          @new-sprint="openModalCreate = true"
          @open-trash="showTrash = !showTrash"
      />
      <Kanban :date="date" :show-trash="showTrash" :workspace-id="workspaceId"/>

    </template>

    <NewSprintModal v-model:show="openModalCreate" @start-sprint="createNewSprint"/>

  </div>
</template>

<script>
import { ref, reactive, computed, watch, toRefs, onMounted, nextTick } from "vue";
import {getSprint, createSprint, completeSprint} from "@/api/sprint";
import CircleLoader from "@/components/UI-Kit/loaders/CircleLoader";
import IconNew from "@/components/UI-Kit/IconNew";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import Modal from "@/components/UI-Kit/Modal";
import Kanban from "@/components/Sprint/Kanban";
import SprintDetails from "@/components/Sprint/SprintDetails";
import NewSprintModal from "@/components/Sprint/NewSprintModal";
import UIkit from 'uikit';
import moment from 'moment';

const sprintSetup = function (props) {
  const NOT_DATE = Date.parse("2000-09-10T00:00:00.000Z")

  const sprints = reactive([])
  const statusLoadSprint = ref('loading')
  const openModalCreate = ref(false)
  const showTrash = ref(false)
  const date = computed(() => {
    if (sprints[activeSprint.value].hasOwnProperty('realEndDate') && Date.parse(sprints[activeSprint.value].realEndDate) !== NOT_DATE) {
      return () => sprints[activeSprint.value].realEndDate
    } else {
      return () => (new Date()).toISOString()
    }
  })

  const activeSprint = ref(0)
  // video
  const showFirstVideo = ref(false)

  const createNewSprint = function (period) {

    if (sprints.length !== 0 && !sprints[sprints.length - 1].hasOwnProperty('startDate')) {
      sprints.splice(sprints.length - 1, 1)
    }

    const ind = sprints.push({
      sync: true,
      ...period
    })

    createSprint(props.workspaceId, period)
        .then((data) => {
          sprints[ind - 1] = data
        })

    activeSprint.value = sprints.length - 1

    if (statusLoadSprint.value === 'not found') {
      statusLoadSprint.value = 'loaded'
    }

    openModalCreate.value = false
  }

  // complete

  const completeLastSprint = function () {
    const ind = sprints.length - 1

    completeSprint(props.workspaceId)
        .then((data) => sprints[ind] = data)

    sprints[ind].realEndDate = new Date().toISOString()
    sprints[ind].sync = true

    activeSprint.value = sprints.push({
      workspace: props.workspaceId
    }) - 1

  }
  // choice sprint
  const dropdownOpen = ref(false)

  const chooseSprint = (index) => {
    UIkit.dropdown(document.querySelector('.sprint-choice-dropdown')).hide()
    activeSprint.value = index
  }

  const initSprint = function() {
    sprints.splice(0, sprints.length)
    statusLoadSprint.value = 'loading'
    activeSprint.value = 0

    getSprint(props.workspaceId).then(data => {
      if (data.length > 0) {
        // TODO: future if need
        data.sort((a, b) => Date.parse(a.startDate) - Date.parse(b.startDate))
        sprints.push(...data)

        if (sprints[sprints.length - 1].hasOwnProperty('realEndDate') && Date.parse(sprints[sprints.length - 1].realEndDate) !== NOT_DATE) {
          sprints.push({
            workspace: props.workspaceId
          })
        }

        activeSprint.value = sprints.length - 1
        statusLoadSprint.value = 'loaded'
      } else {
        statusLoadSprint.value = 'not found'
      }
    })
  }

  initSprint()

  watch(() => props.workspaceId, (value) => {
    initSprint()
  })

  watch(statusLoadSprint, (value) => {
    if (value === 'loaded') {
      nextTick(() => {
        UIkit.util.on('.sprint-choice-dropdown', 'hide', function () {
          dropdownOpen.value = false
        });
      })
    }
  })

  return {
    NOT_DATE,
    sprints,
    statusLoadSprint,
    showFirstVideo,
    date,
    createNewSprint,
    activeSprint,
    openModalCreate,
    dropdownOpen,
    chooseSprint,
    showTrash,
    completeLastSprint
  }
}


export default {
  name: "Sprints",
  components: {NewSprintModal, SprintDetails, Kanban, ButtonNew, IconNew, CircleLoader, Modal},
  props: {
    workspaceId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    return {
      ...sprintSetup(props)
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";
.sprints {
  background-color: #f4f5f8;
  width: 100vw;
  min-height: calc(100vh - 48px);
  padding: 50px;
  padding-bottom: 0;
  text-align: left;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}


.empty-all {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-items: center;
  align-self: center;
  width: calc(100vw - 100px);
  margin: auto;
  text-align: center;

  & .icon {
    width: 179px;
    height: 84px;
    margin: 0 auto;
  }
  h1 {}
  & .button {
    width: 167px;
    margin: 0 auto;
  }
  p {

  }
}

.sprints-load {
  place-self: center center;
  margin: auto;
  height: 100%;
}

.sprint-choice {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropdown-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  &.open {
    transform: rotate(180deg);
  }
}

.sprint-choice-dropdown {
  padding: 0;
}
.sprint-choice-list {
  padding: 0;
  .sprint-choice-item {
    cursor: pointer;
    background-color: $pxp-white-color;
    width: 100%;
    height: 40px;
    padding: 10px;

    &:hover {
      background-color: $pxp-white-color-darken;
    }

    &.now {

    }

    &.success {

    }

    &.active {
      background-color: $pxp-white-accent-color-brighter;
    }
  }
}


</style>
