<template>
  <Modal id="create-rq-modal" header footer v-model:show="show" is-center padding-not-remove>
    <template v-slot:header>
      <h1>New Research Question</h1>
    </template>
    <div class="create-rq-form">
      <h2>What do you want to explore? <span class="required">*</span></h2>

      <TextInputNew
          id="name-new-rq"
          placeholder="Good research is driven by good questions"
          name="name-new-rq"
          v-model="name"
          size="m"
      />

      <h2 class="details">Details / Methodological approach</h2>

      <TextArea mode="account"
                name="details-new-rq"
                placeholder="Optional"
                id="details-new-rq"
                v-model="details">

      </TextArea>
    </div>

    <template v-slot:footer>
      <div class="action">
        <ButtonNew size="l" text="Done" mode="green" class="button" @click="saveNewResearchQuestion"/>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/UI-Kit/Modal";
import TextArea from "@/components/UI-Kit/TextArea";
import TextInputNew from "@/components/UI-Kit/TextInputNew";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import {createResearchQuestion} from "@/api/sprint";
import {ref, reactive, computed, watch, onMounted, nextTick, toRef, toRefs} from "vue"
import moment from "moment"

const createRqSetup = function(props, {emit}) {
  const newRqInput = reactive({
    name: '',
    details: '',
    move: [{
      neighborTop: '',
      neighborBottom: 'column-bottom',
      column: 'rq'
    }],
    tasks: []
  })
  const newRqInputRefs = toRefs(newRqInput)

  const saveNewResearchQuestion = function () {
    newRqInput.move[0].neighborTop = props.neighborTop.type + '-' + props.neighborTop.id
    newRqInput.move[0].neighborBottom = props.neighborBottom.type + '-' + props.neighborBottom.id
    newRqInput.move[0].date = new Date().toISOString()

    const newRq = {...newRqInput}

    newRq.sync = true

    newRqInput.name = ''
    newRqInput.details = ''
    newRqInput.move = [{
      neighborTop: '',
      neighborBottom: 'column-bottom',
      column: 'rq'
    }]
    newRqInput.tasks = []

    emit('new-rq-added', newRq)
    emit('update:show', false)
  }

  watch(() => props.show, (newValue) => {
    emit("update:show", newValue)
  })

  return {
    saveNewResearchQuestion,
    ...newRqInputRefs
  }
}

export default {
  name: "NewResearchModal",
  components: {TextInputNew, TextArea, Modal, ButtonNew},
  props: {
    show: {
      type: Boolean,
      required: true
    },
    neighborTop: {
      type: Object,
      required: true
    },
    neighborBottom: {
      type: Object,
      required: false,
      default: {
        type: 'column',
        id: 'bottom'
      }
    },
    workspaceId: {
      type: String,
      required: true
    }
  },
  setup: createRqSetup
}
</script>

<style scoped lang="scss">
@import '~@/assets/styles/color-scheme.scss';

.action {
  .button {
    width: 140px;
  }
}



.create-rq-form {
  .required {
    color: $pxp-red-color;
  }

  h2 {
    margin-bottom: 16px;
  }

  .details {
    margin-top: 32px;
  }
}

h1 {
  margin-top: 12px;
}

</style>