<!--suppress JSUnfilteredForInLoop -->
<template>
  <div class="kanban">
    <div class="trash" v-show="showTrash">
      <draggable
          :list="sortedData.deleted"
          group="tasks"
          class="dragzone"
          @change="fixChangeInDeleted"
      >

        <template #item="{element, index}">
          <template v-if="element.type === 'tsk'">
            <TaskCard class="task-card" mode="sprint" :task="element" />
          </template>
          <template v-else>
            <ResearchQuestionCard class="rq-card" mode="sprint" :research-question="element" @new-task-added="addTaskToRqEnd"/>
          </template>
        </template>
      </draggable>
    </div>
    <div class="column rq">
      <h2 class="black">Research Questions</h2>
      <div class="active-section">
        <template v-if="sortedData.rq.length === 0">
          <div class="section-empty">
            <IconNew name="sprint-empty-rq" class="icon"/>
            <p class="secondary-text">
              Good research is driven by<br>
              good questions. What do you want<br>
              to explore?
            </p>
          </div>
        </template>
        <draggable
            :list="sortedData.rq"
            group="tasks"
            class="dragzone"
            @change="fixChangeInRq"
        >

          <template #item="{element, index}">
            <template v-if="element.type === 'tsk'">
              <TaskCard mode="sprint" :task="element" />
            </template>
            <template v-else>
              <ResearchQuestionCard mode="sprint" :research-question="element" @new-task-added="addTaskToRqEnd"/>
            </template>


          </template>
          <template #footer>
            <div class="rq-active">
              <div v-if="sortedData.rq.length === 0" class="separate">

              </div>
              <ButtonNew mode="text"
                         icon-name="plus-blue"
                         class="button"
                         text="Add research question"
                         size="s"
                         icon-position="left"
                         @click="openCreateRqModal = true"
              />
            </div>
          </template>
        </draggable>
      </div>
    </div>
    <div class="column todo">
      <h2 class="black">To Do</h2>
      <div class="active-section">
        <template v-if="sortedData.todo.length === 0">
          <div class="section-empty">
            <IconNew name="sprint-empty-todo" class="icon"/>
            <p class="secondary-text">
              Drag here to start
            </p>
          </div>
        </template>
        <draggable
            :list="sortedData.todo"
            group="tasks"
            class="dragzone"
            @change="fixChangeInTodo"
        >
          <template #item="{element, index}">
            <TaskCard mode="sprint" :task="element" />
          </template>
        </draggable>

      </div>
    </div>
    <div class="column doing">
      <h2 class="black">Doing</h2>
      <div class="active-section">
        <template v-if="sortedData.doing.length === 0">
          <div class="section-empty">
            <IconNew name="sprint-empty-doing" class="icon"/>
            <p class="secondary-text">
              Deep dive and investigate
            </p>
          </div>
        </template>
        <draggable
            :list="sortedData.doing"
            group="tasks"
            class="dragzone"
            @change="fixChangeInDoing"
        >
          <template #item="{element, index}">
            <TaskCard mode="sprint" :task="element" />
          </template>


        </draggable>
      </div>
    </div>
    <div class="column done">
      <h2 class="black">Done</h2>
      <div class="active-section">
        <template v-if="sortedData.done.length === 0">
          <div class="section-empty">
            <IconNew name="sprint-empty-done" class="icon"/>
            <p class="secondary-text">
              What have you learnt?
            </p>
          </div>
        </template>
        <draggable
            :list="sortedData.done"
            group="tasks"
            class="dragzone"
            @change="fixChangeInDone"
        >

          <template #item="{element, index}">
            <template v-if="element.type === 'tsk'">
              <TaskCard mode="sprint" :task="element" />
            </template>
            <template v-else>
              <ResearchQuestionCard mode="sprint" :research-question="element" @new-task-added="addTaskToRqEnd"/>
            </template>
          </template>

        </draggable>
      </div>
    </div>
  </div>
  <DoneResearchQuestion v-model:show="openDoneRqModal" :rq="doneRq" @move-to-done="moveRqToDone"/>
  <NewResearchModal v-model:show="openCreateRqModal" :workspace-id="workspaceId" :neighbor-top="sortedData['rq'].length === 0? {type: 'column', id: 'top'} : sortedData['rq'][sortedData['rq'].length - 1]" @new-rq-added="addRqToEnd"/>
</template>

<script>
import {ref, reactive, computed, watch, onMounted, nextTick, toRef, toRefs, inject} from "vue"

import draggable from "vuedraggable";
import {getResearchQuestion, getTask, createResearchQuestion, moveTask, moveResearchQuestion, createTask} from "@/api/sprint";
import TaskCard from "@/components/Research Question/TaskCard";
import IconNew from "@/components/UI-Kit/IconNew";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import NewResearchModal from "@/components/Research Question/NewResearchModal";
import ResearchQuestionCard from "@/components/Research Question/ResearchQuestionCard";
import moment from "moment";
import DoneResearchQuestion from "@/components/Research Question/DoneResearchQuestion";
import {v1 as uuid} from "uuid";


const setup = function (props, {emit}) {

  // main data
  const tasks = inject('tasks')
  const rqs = inject('rqs')

  // Filter task by status
  const sortedData = inject('sortedRQData') //computed(() => getSortedData(rqs, tasks, props.date()))
  const findPlaceByDate = inject('findPlaceByDate')
  const date = inject('date')

  date.value = props.date

  watch(() => props.date, (value) => {
    date.value = props.date
  })
  /*
  const findPlaceByDate = function (entity, date) {
    const move = entity.move
    const searchDate = Date.parse(date)


    let startIndex = 0;
    let endIndex = move.length - 1;

    while(startIndex !== endIndex) {
      let middleIndex = Math.ceil((startIndex + endIndex) / 2);

      if(searchDate >= Date.parse(move[middleIndex].date))
        startIndex = middleIndex;
      else
        endIndex = middleIndex - 1;
    }


    if (Date.parse(move[startIndex].date) <= searchDate && endIndex !== -1)
      return move[startIndex]
    else
      return undefined



  }
   */
  /*
  const getSortedData = function(rqs, tasks, date) {

    const sorted = reactive({
      rq: reactive([]),
      todo: reactive([]),
      doing: reactive([]),
      done: reactive([]),
      deleted: reactive([])
    })

    // find first item in all list

    for (let rqKey in rqs) {

      let rq = rqs[rqKey]

      if (!rq.hasOwnProperty('type'))
        rq.type = "rq"

      if (!rq.hasOwnProperty('tasks'))
        rq.tasks = []

      if (rq.hasOwnProperty('rqList'))
        rq.rqList.splice(0, rq.rqList.length)
      else
        rq.rqList = []

      let rqPlace = findPlaceByDate(rq, date)

      if (typeof rqPlace !== 'undefined') {

        if (rqPlace.neighborTop === 'column-top')
          sorted[rqPlace.column].push(rq)

        for (let rqTask of rq.tasks) {

          let task = tasks[rqTask.id]
          if (!task.hasOwnProperty('type'))
            task.type = 'tsk'

          let taskPlace = findPlaceByDate(task, date)

          if (typeof taskPlace !== 'undefined') {
            if (taskPlace.neighborTop === 'column-top')
              sorted[taskPlace.column].push(task)
            else if (taskPlace.neighborTop === 'rq-top')
              rq.rqList.push(task)
          }
        }
      }
    }

    // set all item in order

    for (let column in sorted) {
      if (sorted[column].length > 0) {
        let flag = true

        while (flag) {
          let now = sorted[column][sorted[column].length - 1]

          if (now.type === 'rq') {
            if (now.rqList.length > 0) {
              let rqFlag = true

              while (rqFlag) {
                let nowTask = now.rqList[now.rqList.length - 1]
                let nextTask = findPlaceByDate(nowTask, date).neighborBottom

                if (typeof nowTask.researchQuestion === 'object') {
                  nowTask.researchQuestion = nowTask.researchQuestion.id
                }

                if (nextTask === 'rq-bottom') {
                  rqFlag = false
                  break
                } else {
                  const taskId = nextTask.split('-')[1]
                  const task = tasks[taskId]

                  now.rqList.push(task)
                }
              }
            }
          }
          else {
            let nowRqId

            if (typeof now.researchQuestion === 'object')
              nowRqId = now.researchQuestion.id
            else
              nowRqId = now.researchQuestion

            if (sorted[column].length - 1 === 0 || sorted[column][sorted[column].length - 2].type !== 'tsk') {
              now.researchQuestion = {
                id: nowRqId,
                name: rqs[nowRqId].name
              }
            } else {
              const lastItem = sorted[column][sorted[column].length - 2]

              let lastRqId
              if (typeof lastItem.researchQuestion === 'object')
                lastRqId = lastItem.researchQuestion.id
              else
                lastRqId = lastItem.researchQuestion

              if (lastRqId === nowRqId) {
                now.researchQuestion = nowRqId
              } else {
                now.researchQuestion = {
                  id: nowRqId,
                  name: rqs[nowRqId].name
                }
              }
            }
          }

          let next = findPlaceByDate(now, date).neighborBottom

          if (next === 'column-bottom') {
            flag = false
            break
          } else if (next.split('-')[0] === 'rq') {

            const rqId = next.split('-')[1]
            const rq = rqs[rqId]

            sorted[column].push(rq)

          } else {

            const taskId = next.split('-')[1]
            const task = tasks[taskId]

            sorted[column].push(task)

          }
        }
      }
    }

    return sorted
  }*/

  // Init
  const loadStatus = inject('loadRQStatus')//ref('loading')

  /*
  const init = async function () {
    loadStatus.value = 'loading'

    const rqsData = await getResearchQuestion(props.workspaceId)
    const tasksData = await getTask(props.workspaceId)

    for (let id in rqs)
      delete rqs[id];

    for (let id in tasks)
      delete tasks[id];


    rqsData.forEach(item => {
      rqs[item.id] = reactive(item)
    })

    tasksData.forEach(item => {
      tasks[item.id] = reactive(item)
    })

    loadStatus.value = 'loaded'
  }
  init()
  watch (() => props.workspaceId, () => {
    init()
  })*/
  // Research Question
  const openCreateRqModal = ref(false)
  const openDoneRqModal = ref(false)
  const doneRq = ref({})
  const addRqToEnd = function(rq) {

    const syncId = uuid().split('-').join('');
    rq.id = syncId
    rq.type = 'rq'

    rqs[syncId] = rq
    fixMove('', '', rq.move[0].neighborTop, rq.move[0].neighborBottom, rq.move[0].date, 'rq-' + syncId)

    createResearchQuestion(props.workspaceId, rq)
      .then((data) => {
        delete rqs[syncId]
        data.type = 'rq'
        data.sync = false
        rqs[data.id] = data
        fixMove('', '', data.move[0].neighborTop, data.move[0].neighborBottom, data.move[0].date, 'rq-' + data.id)
      })
  }

  const moveTasksToRq = async function(rq, column) {
    const date = new Date().toISOString()

    for (let ind in rq.tasks) {
      const index = Number.parseInt(ind)
      const task = rq.tasks[index]

      const taskFull = tasks[task.id]
      const lastMove = taskFull.move[taskFull.move.length - 1]

      const newTop = index === 0? 'rq-top' : 'tsk-' + rq.tasks[index - 1].id
      const newBottom = 'rq-bottom'

      fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + taskFull.id)

      const selfTaskMove = {
        date: date,
        neighborTop: newTop,
        neighborBottom: newBottom,
        column: column
      }

      tasks[task.id].move.push(selfTaskMove)

      fixMove("", "", newTop, newBottom, date, 'tsk-' + taskFull.id)

      taskFull.sync = true

      await moveTask(taskFull.id, props.workspaceId, selfTaskMove)
          .then(() => {
            taskFull.sync = false
          })

    }
  }

  const moveRqToDone = async function(rq) {
    const date = new Date().toISOString()
    const lastMove = rq.move[rq.move.length - 1]
    const newTop = 'column-top'
    const newBottom = sortedData.value.done.length !== 0?
        sortedData.value.done[0].type + '-' + sortedData.value.done[0].id : 'column-bottom'

    fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'rq-' + rq.id)

    const selfMove = {
      date: date,
      neighborTop: newTop,
      neighborBottom: newBottom,
      column: 'done'
    }

    rq.move.push(selfMove)

    fixMove("", "", newTop, newBottom, date, 'rq-' + rq.id)

    rqs[rq.id].sync = true

    await moveResearchQuestion(rq.id, props.workspaceId, selfMove)

    await moveTasksToRq(rq, 'done')
    rqs[rq.id].sync = false
  }

  // Tasks
  const addTaskToRqEnd = function(task) {
    const syncId = uuid().split('-').join('');
    task.id = syncId
    task.type = 'tsk'
    tasks[syncId] = task
    rqs[task.researchQuestion].tasks.push(task)
    const index = rqs[task.researchQuestion].tasks.length - 1
    fixMove('', '', task.move[0].neighborTop, task.move[0].neighborBottom, task.move[0].date, 'tsk-' + syncId)

    createTask(props.workspaceId, task)
        .then((data) => {
          delete tasks[syncId]
          data.type = 'tsk'
          data.sync = false
          tasks[data.id] = data
          rqs[task.researchQuestion].tasks[index] = data
          fixMove('', '', data.move[0].neighborTop, data.move[0].neighborBottom, data.move[0].date, 'tsk-' + data.id)
        })
  }

  // Fix move
  const fixMove = function(lastTop, lastBottom, newTop, newBottom, date, self) {
    if (lastTop !== "" && lastBottom !== "") {
      if (lastTop !== 'rq-top' && lastTop !== 'column-top') {
        let entityLastTop, entityLastTopId, entityLastTopObject

        entityLastTop = lastTop.split('-')[0]
        entityLastTopId = lastTop.split('-')[1]

        if (entityLastTop === 'rq') {
          entityLastTopObject = rqs[entityLastTopId]
        } else {
          entityLastTopObject = tasks[entityLastTopId]
        }

        const lastTopMove = entityLastTopObject.move
        const lastLastTopMove = lastTopMove[lastTopMove.length - 1]

        const newLastTopMove = {
          date: date,
          neighborTop: lastLastTopMove.neighborTop,
          neighborBottom: lastBottom,
          column: lastLastTopMove.column
        }

        lastTopMove.push(newLastTopMove)
      }
      if (lastBottom !== 'rq-bottom' && lastBottom !== 'column-bottom') {
        let entityLastBottom, entityLastBottomId, entityLastBottomObject

        entityLastBottom = lastBottom.split('-')[0]
        entityLastBottomId = lastBottom.split('-')[1]

        if (entityLastBottom === 'rq') {
          entityLastBottomObject = rqs[entityLastBottomId]
        } else {
          entityLastBottomObject = tasks[entityLastBottomId]
        }

        const lastBottomMove = entityLastBottomObject.move
        const lastLastBottomMove = lastBottomMove[lastBottomMove.length - 1]

        const newLastBottomMove = {
          date: date,
          neighborTop: lastTop,
          neighborBottom: lastLastBottomMove.neighborBottom,
          column: lastLastBottomMove.column
        }

        lastBottomMove.push(newLastBottomMove)
      }
    }

    if (newTop !== "" && newBottom !== "") {
      if (newTop !== 'rq-top' && newTop !== 'column-top') {
        let entityTop, entityTopId, entityTopObject

        entityTop = newTop.split('-')[0]
        entityTopId = newTop.split('-')[1]

        if (entityTop === 'rq') {
          entityTopObject = rqs[entityTopId]
        } else {
          entityTopObject = tasks[entityTopId]
        }

        const topMove = entityTopObject.move
        const lastForTopMove = topMove[topMove.length - 1]

        const newTopMove = {
          date: date,
          neighborTop: lastForTopMove.neighborTop,
          neighborBottom: self,
          column: lastForTopMove.column
        }

        topMove.push(newTopMove)
      }
      if (newBottom !== 'rq-bottom' && newBottom !== 'column-bottom') {
        let entityBottom, entityBottomId, entityBottomObject

        entityBottom = newBottom.split('-')[0]
        entityBottomId = newBottom.split('-')[1]

        if (entityBottom === 'rq') {
          entityBottomObject = rqs[entityBottomId]
        } else {
          entityBottomObject = tasks[entityBottomId]
        }

        const bottomMove = entityBottomObject.move
        const lastForBottomMove = bottomMove[bottomMove.length - 1]

        const newBottomMove = {
          date: date,
          neighborTop: self,
          neighborBottom: lastForBottomMove.neighborBottom,
          column: lastForBottomMove.column
        }

        bottomMove.push(newBottomMove)
      }
    }
  }

  // Fix move in column
  const fixChangeInRq = function (changeInfo) {
    if (changeInfo.hasOwnProperty('added')) {
      // only tasks
      const { element, newIndex } = changeInfo.added

      if (element.type === 'rq') {
        let newTop, newBottom
        const date = new Date().toISOString()
        let lastMove = element.move[element.move.length - 1]

        if (newIndex > 0) {
          let elementTop = sortedData.value.rq[newIndex - 1]
          newTop = elementTop.type + '-' + elementTop.id
        } else {
          newTop = 'column-top'
        }

        if (newIndex < sortedData.value.rq.length) {
          let elementBottom = sortedData.value.todo[newIndex]
          newBottom = elementBottom.type + '-' + elementBottom.id
        } else {
          newBottom = 'column-bottom'
        }

        fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'rq-' + element.id)

        const selfMove = {
          date: date,
          neighborTop: newTop,
          neighborBottom: newBottom,
          column: 'rq'
        }

        element.move.push(selfMove)

        fixMove("", "", newTop, newBottom, date, 'rq-' + element.id)

        rqs[element.id].sync = true

        moveResearchQuestion(element.id, props.workspaceId, selfMove)
            .then(() => {
              rqs[element.id].sync = false
            })
      }
      else {
        let newTop, newBottom, rqId
        const date = new Date().toISOString()
        let lastMove = element.move[element.move.length - 1]

        if (typeof element.researchQuestion === 'object')
          rqId = element.researchQuestion.id
        else
          rqId = element.researchQuestion

        const newRqIndex = rqs[rqId].rqList.length

        if (newIndex > 0) {
          let elementTop = rqs[rqId].rqList[newIndex - 1]
          newTop = elementTop.type + '-' + elementTop.id
        } else {
          newTop = 'rq-top'
        }

        newBottom = 'rq-bottom'

        fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

        const selfMove = {
          date: date,
          neighborTop: newTop,
          neighborBottom: newBottom,
          column: 'rq'
        }

        element.move.push(selfMove)

        fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

        tasks[element.id].sync = true

        moveTask(element.id, props.workspaceId, selfMove)
            .then(() => {
              tasks[element.id].sync = false
            })
      }
    }
    else if (changeInfo.hasOwnProperty('moved')) {
      const { element, oldIndex, newIndex } = changeInfo.moved

      let newTop, newBottom
      const date = new Date().toISOString()
      let lastMove = element.move[element.move.length - 1]

      if (newIndex === 0)
        newTop = { type: 'column', id: 'top' }
      else if (newIndex < oldIndex)
        newTop = sortedData.value.rq[newIndex - 1]
      else
        newTop = sortedData.value.rq[newIndex]

      if (newIndex === sortedData.value.rq.length - 1)
        newBottom = { type: 'column', id: 'bottom' }
      else if (newIndex < oldIndex)
        newBottom = sortedData.value.rq[newIndex]
      else
        newBottom = sortedData.value.rq[newIndex + 1]

      newTop = newTop.type + '-' + newTop.id
      newBottom = newBottom.type + '-' + newBottom.id

      fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'rq-' + element.id)

      const selfMove = {
        date: date,
        neighborTop: newTop,
        neighborBottom: newBottom,
        column: 'rq'
      }

      element.move.push(selfMove)

      fixMove("", "", newTop, newBottom, date, 'rq-' + element.id)

      rqs[element.id].sync = true

      moveResearchQuestion(element.id, props.workspaceId, selfMove)
          .then(() => {
            rqs[element.id].sync = false
          })
    }
  }
  const fixChangeInTodo = function (changeInfo) {
    if (changeInfo.hasOwnProperty('added')) {
      // only tasks
      const { element, newIndex } = changeInfo.added

        if (element.type === 'tsk') {
          let newTop, newBottom
          const date = new Date().toISOString()
          let lastMove = element.move[element.move.length - 1]

          if (newIndex > 0) {
            let elementTop = sortedData.value.todo[newIndex - 1]
            newTop = elementTop.type + '-' + elementTop.id
          } else {
            newTop = 'column-top'
          }

          if (newIndex < sortedData.value.todo.length) {
            let elementBottom = sortedData.value.todo[newIndex]
            newBottom = elementBottom.type + '-' + elementBottom.id
          } else {
            newBottom = 'column-bottom'
          }

          fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

          const selfMove = {
            date: date,
            neighborTop: newTop,
            neighborBottom: newBottom,
            column: 'todo'
          }

          element.move.push(selfMove)

          fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

          tasks[element.id].sync = true

          moveTask(element.id, props.workspaceId, selfMove)
              .then(() => {
                tasks[element.id].sync = false
              })
        } else {
          alert('Only task!')
        }
    }
    else if (changeInfo.hasOwnProperty('moved')) {
      const { element, oldIndex, newIndex } = changeInfo.moved

      let newTop, newBottom
      const date = new Date().toISOString()
      let lastMove = element.move[element.move.length - 1]

      if (newIndex === 0)
        newTop = { type: 'column', id: 'top' }
      else if (newIndex < oldIndex)
        newTop = sortedData.value.todo[newIndex - 1]
      else
        newTop = sortedData.value.todo[newIndex]

      if (newIndex === sortedData.value.todo.length - 1)
        newBottom = { type: 'column', id: 'bottom' }
      else if (newIndex < oldIndex)
        newBottom = sortedData.value.todo[newIndex]
      else
        newBottom = sortedData.value.todo[newIndex + 1]

      newTop = newTop.type + '-' + newTop.id
      newBottom = newBottom.type + '-' + newBottom.id

      fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

      const selfMove = {
        date: date,
        neighborTop: newTop,
        neighborBottom: newBottom,
        column: 'todo'
      }

      element.move.push(selfMove)

      fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

      tasks[element.id].sync = true

      moveTask(element.id, props.workspaceId, selfMove)
          .then(() => {
            tasks[element.id].sync = false
          })
    }
  }
  const fixChangeInDoing = function (changeInfo) {
    if (changeInfo.hasOwnProperty('added')) {
      // only tasks
      const { element, newIndex } = changeInfo.added

      if (element.type === 'tsk') {
        let newTop, newBottom
        const date = new Date().toISOString()
        let lastMove = element.move[element.move.length - 1]

        if (newIndex > 0) {
          let elementTop = sortedData.value.doing[newIndex - 1]
          newTop = elementTop.type + '-' + elementTop.id
        } else {
          newTop = 'column-top'
        }

        if (newIndex < sortedData.value.doing.length) {
          let elementBottom = sortedData.value.doing[newIndex]
          newBottom = elementBottom.type + '-' + elementBottom.id
        } else {
          newBottom = 'column-bottom'
        }

        fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

        const selfMove = {
          date: date,
          neighborTop: newTop,
          neighborBottom: newBottom,
          column: 'doing'
        }

        element.move.push(selfMove)

        fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

        tasks[element.id].sync = true

        moveTask(element.id, props.workspaceId, selfMove)
            .then(() => {
              tasks[element.id].sync = false
            })
      } else {
        alert('Only task!')
      }
    }
    else if (changeInfo.hasOwnProperty('moved')) {
      const { element, oldIndex, newIndex } = changeInfo.moved

      let newTop, newBottom
      const date = new Date().toISOString()
      let lastMove = element.move[element.move.length - 1]

      if (newIndex === 0)
        newTop = { type: 'column', id: 'top' }
      else if (newIndex < oldIndex)
        newTop = sortedData.value.doing[newIndex - 1]
      else
        newTop = sortedData.value.doing[newIndex]

      if (newIndex === sortedData.value.doing.length - 1)
        newBottom = { type: 'column', id: 'bottom' }
      else if (newIndex < oldIndex)
        newBottom = sortedData.value.doing[newIndex]
      else
        newBottom = sortedData.value.doing[newIndex + 1]

      newTop = newTop.type + '-' + newTop.id
      newBottom = newBottom.type + '-' + newBottom.id

      fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

      const selfMove = {
        date: date,
        neighborTop: newTop,
        neighborBottom: newBottom,
        column: 'doing'
      }

      element.move.push(selfMove)

      fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

      tasks[element.id].sync = true

      moveTask(element.id, props.workspaceId, selfMove)
          .then(() => {
            tasks[element.id].sync = false
          })
    }
  }
  const fixChangeInDone = function (changeInfo) {
    if (changeInfo.hasOwnProperty('added')) {
      // only tasks
      const { element, newIndex } = changeInfo.added

      if (element.type === 'tsk') {

        // check all in done:

        let rqId, flag = true

        if (typeof element.researchQuestion === 'object')
          rqId = element.researchQuestion.id
        else
          rqId = element.researchQuestion

        rqs[rqId].tasks.forEach(item => {
          let task = tasks[item.id]
          let taskPlace = findPlaceByDate(task, props.date())
          if ((item.id !== element.id) && (!taskPlace || taskPlace.column !== 'done')) {
            flag = false
          }
        })

        if (flag) {
          doneRq.value = rqs[rqId]
          openDoneRqModal.value = true
        }

        let newTop, newBottom
        const date = new Date().toISOString()
        let lastMove = element.move[element.move.length - 1]

        if (newIndex > 0) {
          let elementTop = sortedData.value.done[newIndex - 1]
          newTop = elementTop.type + '-' + elementTop.id
        } else {
          newTop = 'column-top'
        }

        if (newIndex < sortedData.value.done.length) {
          let elementBottom = sortedData.value.done[newIndex]
          newBottom = elementBottom.type + '-' + elementBottom.id
        } else {
          newBottom = 'column-bottom'
        }

        fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

        const selfMove = {
          date: date,
          neighborTop: newTop,
          neighborBottom: newBottom,
          column: 'done'
        }

        element.move.push(selfMove)

        fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

        tasks[element.id].sync = true

        moveTask(element.id, props.workspaceId, selfMove)
            .then(() => {
              tasks[element.id].sync = false
            })
      } else {
        alert('Only task!')
      }
    }
    else if (changeInfo.hasOwnProperty('moved')) {
      const { element, oldIndex, newIndex } = changeInfo.moved

      let newTop, newBottom
      const date = new Date().toISOString()
      let lastMove = element.move[element.move.length - 1]

      if (newIndex === 0)
        newTop = { type: 'column', id: 'top' }
      else if (newIndex < oldIndex)
        newTop = sortedData.value.done[newIndex - 1]
      else
        newTop = sortedData.value.done[newIndex]

      if (newIndex === sortedData.value.done.length - 1)
        newBottom = { type: 'column', id: 'bottom' }
      else if (newIndex < oldIndex)
        newBottom = sortedData.value.done[newIndex]
      else
        newBottom = sortedData.value.done[newIndex + 1]

      newTop = newTop.type + '-' + newTop.id
      newBottom = newBottom.type + '-' + newBottom.id

      fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

      const selfMove = {
        date: date,
        neighborTop: newTop,
        neighborBottom: newBottom,
        column: 'done'
      }

      element.move.push(selfMove)

      fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

      tasks[element.id].sync = true

      moveTask(element.id, props.workspaceId, selfMove)
          .then(() => {
            tasks[element.id].sync = false
          })
    }
  }
  const fixChangeInDeleted = function (changeInfo) {
    if (changeInfo.hasOwnProperty('added')) {
      const { element, newIndex } = changeInfo.added

      if (element.type === 'tsk') {

        let newTop, newBottom
        const date = new Date().toISOString()
        let lastMove = element.move[element.move.length - 1]

        if (newIndex > 0) {
          let elementTop = sortedData.value.deleted[newIndex - 1]
          newTop = elementTop.type + '-' + elementTop.id
        } else {
          newTop = 'column-top'
        }

        if (newIndex < sortedData.value.deleted.length) {
          let elementBottom = sortedData.value.deleted[newIndex]
          newBottom = elementBottom.type + '-' + elementBottom.id
        } else {
          newBottom = 'column-bottom'
        }

        fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

        const selfMove = {
          date: date,
          neighborTop: newTop,
          neighborBottom: newBottom,
          column: 'deleted'
        }

        element.move.push(selfMove)

        fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

        tasks[element.id].sync = true

        moveTask(element.id, props.workspaceId, selfMove)
            .then(() => {
              tasks[element.id].sync = false
            })
      } else {
        let newTop, newBottom
        const date = new Date().toISOString()
        let lastMove = element.move[element.move.length - 1]

        if (newIndex > 0) {
          let elementTop = sortedData.value.deleted[newIndex - 1]
          newTop = elementTop.type + '-' + elementTop.id
        } else {
          newTop = 'column-top'
        }

        if (newIndex < sortedData.value.deleted.length) {
          let elementBottom = sortedData.value.deleted[newIndex]
          newBottom = elementBottom.type + '-' + elementBottom.id
        } else {
          newBottom = 'column-bottom'
        }

        fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'rq-' + element.id)

        const selfMove = {
          date: date,
          neighborTop: newTop,
          neighborBottom: newBottom,
          column: 'deleted'
        }

        element.move.push(selfMove)

        fixMove("", "", newTop, newBottom, date, 'rq-' + element.id)

        rqs[element.id].sync = true

        moveResearchQuestion(element.id, props.workspaceId, selfMove)
            .then(() => {
              moveTasksToRq(rqs[element.id], 'deleted')
                .then(() => {
                  rqs[element.id].sync = false
                })
            })
      }
    }
    else if (changeInfo.hasOwnProperty('moved')) {
      const { element, oldIndex, newIndex } = changeInfo.moved

      let newTop, newBottom
      const date = new Date().toISOString()
      let lastMove = element.move[element.move.length - 1]

      if (newIndex === 0)
        newTop = { type: 'column', id: 'top' }
      else if (newIndex < oldIndex)
        newTop = sortedData.value.done[newIndex - 1]
      else
        newTop = sortedData.value.done[newIndex]

      if (newIndex === sortedData.value.done.length - 1)
        newBottom = { type: 'column', id: 'bottom' }
      else if (newIndex < oldIndex)
        newBottom = sortedData.value.done[newIndex]
      else
        newBottom = sortedData.value.done[newIndex + 1]

      newTop = newTop.type + '-' + newTop.id
      newBottom = newBottom.type + '-' + newBottom.id

      fixMove(lastMove.neighborTop, lastMove.neighborBottom, "", "", date, 'tsk-' + element.id)

      const selfMove = {
        date: date,
        neighborTop: newTop,
        neighborBottom: newBottom,
        column: 'done'
      }

      element.move.push(selfMove)

      fixMove("", "", newTop, newBottom, date, 'tsk-' + element.id)

      tasks[element.id].sync = true

      moveTask(element.id, props.workspaceId, selfMove)
          .then(() => {
            tasks[element.id].sync = false
          })
    }
  }

  // Collaboration events

  const socket = inject('socket')
  const user = inject('user')

  socket.value.on('created_research-question', (data) => {
    const rq = data.data
    const authorId = data.author
    rq.sync = false
    rq.type = 'rq'
    if (authorId !== user.id) {
      rqs[rq.id] = rq
      fixMove('', '', rq.move[0].neighborTop, rq.move[0].neighborBottom, rq.move[0].date, 'rq-' + rq.id)
    }
  })

  socket.value.on('updated_research-question', (data) => {
    const rq = data.data
    const authorId = data.author

    if (authorId !== user.id) {
      rqs[rq.id].name = rq.name
      rqs[rq.id].details = rq.details
      rqs[rq.id].learnings = rq.learnings
      rqs[rq.id].plans = rq.plans
    }
  })

  socket.value.on('moved_research-question', (data) => {
    const rq = data.data
    const authorId = data.author

    if (authorId !== user.id) {
      const lastPlace = rqs[rq.id].move[rqs[rq.id].move.length - 1]
      const newPlace = rq.move[rq.move.length - 1]
      fixMove(lastPlace.neighborTop, lastPlace.neighborBottom, '', '', newPlace.date, 'rq-' + rq.id)
      rqs[rq.id].move = rq.move
      fixMove('', '', newPlace.neighborTop, newPlace.neighborBottom, newPlace.date, 'rq-' + rq.id)
    }
  })

  socket.value.on('created_task', (data) => {
    const task = data.data
    const authorId = data.author
    task.sync = false
    task.type = 'tsk'
    if (authorId !== user.id) {
      tasks[task.id] = task
      rqs[task.researchQuestion.id].tasks.push(task)
      fixMove('', '', task.move[0].neighborTop, task.move[0].neighborBottom, task.move[0].date, 'tsk-' + task.id)
    }
  })

  socket.value.on('updated_task', (data) => {
    const task = data.data
    const authorId = data.author

    if (authorId !== user.id) {
      tasks[task.id].name = task.name
    }
  })

  socket.value.on('moved_task', (data) => {
    const task = data.data
    const authorId = data.author

    if (authorId !== user.id) {
      const lastPlace = tasks[task.id].move[tasks[task.id].move.length - 1]
      const newPlace = task.move[task.move.length - 1]
      fixMove(lastPlace.neighborTop, lastPlace.neighborBottom, '', '', newPlace.date, 'tsk-' + task.id)
      tasks[task.id].move = task.move
      fixMove('', '', newPlace.neighborTop, newPlace.neighborBottom, newPlace.date, 'tsk-' + task.id)
    }
  })

  return {
    sortedData,
    loadStatus,
    tasks,
    rqs,
    openCreateRqModal,
    addRqToEnd,
    addTaskToRqEnd,
    fixChangeInTodo, fixChangeInDoing, fixChangeInDone, fixChangeInRq, fixChangeInDeleted,
    doneRq, openDoneRqModal,
    moveRqToDone
  }
}

export default {
  name: "Kanban",
  components: {
    DoneResearchQuestion,
    ResearchQuestionCard,
    NewResearchModal,
    ButtonNew,
    IconNew,
    TaskCard,
    draggable
  },
  props: {
    workspaceId: {
      type: String,
      required: true
    },
    date: {
      type: Function,
      required: true
    },
    showTrash: {
      type: Boolean,
      required: true
    }
  },
  setup
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/utility.scss";
.kanban {
  margin-top: 16px;
  overflow-y: hidden;
  flex-grow: 1;
  overflow-x: hidden;
  display: grid;
  width: 100%;
  box-sizing: border-box;
  height: calc(100vh - 161px);
  align-items: start;
  grid-template-areas:
      "trash trash trash trash"
      "rq todo doing done";

  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: max-content;

  h2 {
    font-weight: 400;
    margin-bottom: 16px;
  }

  .trash, .rq, .doing, .todo, .done {
  }

  .trash {
    position: relative;
    grid-area: trash;
    height: 250px;
    width: calc(100% - 16px);
    margin: 0 8px 24px 8px;
    border: 1px dashed #CCD0DB;
    align-self: start;
    border-radius: 8px;

    .dragzone {
      overflow-y: scroll;
      padding: 16px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: flex-start;
      .task-card, .rq-card {
        margin: 4px;
      }
    }
  }
  .rq {
    grid-area: rq;
    height: 100%;
  }
  .todo {
    grid-area: todo;
    height: 100%;
  }
  .doing {
    grid-area: doing;
    height: 100%;
  }
  .done {
    grid-area: done;
    height: 100%;
  }

  .column {
    margin: 0 8px;
    box-sizing: border-box;
    height: 100%;
    align-self: start;
  }
}

.active-section {
  height: 100%;
  position: relative;
  &.empty {
  }

}

.section-empty {
  border: 1px dashed #CCD0DB;
  box-sizing: border-box;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;


  .icon {
    width: 64px;
    height: 64px;

    margin: 72px auto 32px auto;
  }

  p {
    font-size: 12px;
    text-align: center;
    margin-bottom: 72px;
  }
}

.dragzone {
  @include suppress-scroll;
  position: absolute;
  z-index: 3;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}


.rq-active {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;

  .separate {
    height: 225px;
  }

  .button {
    margin: 24px auto 124px auto;
    width: 173px;
  }
}



.dragzone {
  //border: 1px solid red;
}
</style>
